export function HeaderTitle({ title }: { title: string }) {
  return (
    <div
      className={
        "m-0 w-full font-base text-sm font-bold leading-default text-neutral-darkest"
      }
    >
      {title}
    </div>
  );
}
