import {
  accountsQueryKeyFactory,
  listAccountsUseCase,
  QueryConfig,
  SuspenseQueryConfig,
  User,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

export type UseListAccounts = {
  config?: QueryConfig<typeof listAccounts>;
};

export type UseSuspenseListAccounts = {
  config?: SuspenseQueryConfig<typeof listAccounts>;
};

async function listAccounts(): Promise<User> {
  const userResponse = await listAccountsUseCase.execute();
  if (userResponse.isFailure()) {
    throw new Error("Falha ao buscar o usuário.");
  }

  return userResponse.value;
}

const fiveMinutes = 5 * 60 * 1000;

function listAccountsQueryConfig({ config }: UseListAccounts = {}) {
  return {
    queryKey: accountsQueryKeyFactory.list(),
    queryFn: listAccounts,
    staleTime: fiveMinutes,
    ...config,
  };
}

function listAccountsSuspenseQueryConfig({
  config,
}: UseSuspenseListAccounts = {}) {
  return {
    queryKey: accountsQueryKeyFactory.list(),
    queryFn: listAccounts,
    staleTime: fiveMinutes,
    ...config,
  };
}

export const useListAccounts = ({ config }: UseListAccounts = {}) => {
  const query = useQuery(listAccountsQueryConfig({ config }));

  return {
    data: query.data as User,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};

export const useSuspenseListAccounts = ({ config }: UseListAccounts = {}) => {
  const query = useSuspenseQuery(listAccountsSuspenseQueryConfig({ config }));

  return {
    data: query.data,
    isLoading: query.isFetching,
  };
};
