type IconProps = React.SVGProps<SVGSVGElement>;

export const icons = {
  error: (props: IconProps) => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>error</title>
      <rect x={2} y={2} width={20} height={20} rx={10} fill="#9B1600" />
      <path
        d="M12 12.7l-3.383 3.383a.49.49 0 01-.342.142.46.46 0 01-.358-.142.48.48 0 010-.7L11.3 12 7.917 8.617a.491.491 0 01-.142-.342.46.46 0 01.142-.358.48.48 0 01.7 0L12 11.3l3.383-3.383a.494.494 0 01.342-.142.462.462 0 01.358.142.48.48 0 010 .7L12.7 12l3.383 3.383a.49.49 0 01.142.342.46.46 0 01-.142.358.48.48 0 01-.7 0L12 12.7z"
        fill="#fff"
      />
    </svg>
  ),
  empty: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <title>empty</title>
      <rect x={2.5} y={2.5} width={19} height={19} rx={9.5} stroke="#A6A6A6" />
    </svg>
  ),
  success: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <title>success</title>
      <rect x={2} y={2} width={20} height={20} rx={10} fill="#00801E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.333 8l-5.528 5.529-2.862-2.862-.943.942 3.333 3.334a.67.67 0 00.943 0l6-6L16.333 8z"
        fill="#fff"
      />
    </svg>
  ),
};
