// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Button, Dialog, Drawer } from "@hyperlocal/vital2";

type ConfirmChangePasswordProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpdatePassword: () => void;
};

export function ConfirmChangePassword({
  isOpen,
  onClose,
  onUpdatePassword,
}: ConfirmChangePasswordProps) {
  const isDesktop = Hooks.useMediaQuery("desktop");

  return isDesktop ? (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Content className="gap-0">
        <Dialog.Header>
          <Dialog.Title>Alterar senha</Dialog.Title>
        </Dialog.Header>
        <div className="flex flex-col gap-4 mt-6">
          <span className="space-y-2">
            Ao alterar sua senha, você será desconectado de todos os
            dispositivos e precisará fazer o login novamente com a nova senha.
            Deseja realmente prosseguir com a alteração?
          </span>
          <div className="flex w-full gap-2">
            <Button.Root
              type="button"
              variant="secondary"
              onClick={onClose}
              className="w-full"
            >
              Voltar
            </Button.Root>
            <Button.Root
              type="button"
              className="w-full"
              onClick={onUpdatePassword}
            >
              Alterar
            </Button.Root>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  ) : (
    <Drawer.Root open={isOpen}>
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title>Alterar senha</Drawer.Title>
        </Drawer.Header>
        <div className="flex flex-col gap-4 mt-6">
          <span className="space-y-2">
            Ao alterar sua senha, você será desconectado de todos os
            dispositivos e precisará fazer o login novamente com a nova senha.
            Deseja realmente prosseguir com a alteração?
          </span>
          <div className="flex w-full gap-2">
            <Button.Root
              type="button"
              variant="secondary"
              onClick={onClose}
              className="w-full"
            >
              Voltar
            </Button.Root>
            <Button.Root
              type="button"
              className="w-full"
              onClick={onUpdatePassword}
            >
              Alterar
            </Button.Root>
          </div>
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
}
