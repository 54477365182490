import Icon from "@hyperlocal/vital-icons";
import { Sheet } from "@hyperlocal/vital2";
import { Navigation } from "./utils";

interface SidebarMobileProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarMobile = ({ isOpen, setIsOpen }: SidebarMobileProps) => {
  const handleCloseSidebar = () => setIsOpen(false);

  return (
    <Sheet.Root open={isOpen} onOpenChange={setIsOpen}>
      <Sheet.Trigger className="flex gap-2 text-neutral-darkest items-center">
        <Icon name="MenuDefault" fill="currentColor" className="size-6" />
        <h3 className="font-bold text-sm/5 font-base">Banking</h3>
      </Sheet.Trigger>
      <Sheet.Content className="[&>[data-role=close-sheet]]:hidden" side="left">
        <div>
          <Sheet.Close className="mb-6">
            <Icon
              name="SettingTrashClose"
              fill="currentColor"
              className="size-6"
            />
          </Sheet.Close>
          <Navigation isOpen={isOpen} handleCloseSidebar={handleCloseSidebar} />
        </div>
      </Sheet.Content>
    </Sheet.Root>
  );
};
