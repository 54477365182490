import { useState } from "react";
import {
  AccessGroupName,
  getDashboardRoutesData,
  hasPermission,
  Hooks,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import * as Collapsible from "@radix-ui/react-collapsible";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { SidebarIcons } from "./sidebar-icons";

const routes = getDashboardRoutesData();

type NavItem = {
  name: string;
  wrap: boolean;
  icon: (props: IconProps) => JSX.Element;
  path: string;
  allowedRoles?: AccessGroupName[];
  conditionToHide?: boolean;
  children?: Array<{
    name: string;
    wrap: boolean;
    path: string;
    allowedRoles?: AccessGroupName[];
  }>;
};

export interface CollapsibleItemProps {
  item: NavItem;
  sidebarState: boolean;
  handleCloseSidebar: () => void;
}

export const CollapsibleItem = ({
  item,
  sidebarState,
  handleCloseSidebar,
}: CollapsibleItemProps) => {
  const isMobile = Hooks.useMediaQuery("mobile");

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const onSelectNavItem = () => {
    handleCloseSidebar();
    setIsOpen(false);
  };

  if (!item.children) return null;

  if (item.allowedRoles && !hasPermission(item.allowedRoles)) {
    return null;
  }

  return (
    <Collapsible.Root
      open={isOpen && sidebarState}
      onOpenChange={setIsOpen}
      {...(!isMobile && {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
    >
      <Collapsible.Trigger
        className="flex h-12 items-center rounded-sm p-3 hover:bg-primary-dark hover:text-neutral-white w-full gap-2 group"
        data-parent={sidebarState ? "open" : "closed"}
      >
        <item.icon className="h-6 w-6 min-w-8" />
        <span
          className={twMerge(
            "visible flex text-left font-base text-xs/6 font-bold text-neutral-darkest hover:text-neutral-white md:group-hover:text-neutral-white opacity-100 data-[selected=true]:text-neutral-white gap-2 justify-between items-center transition-all duration-0 overflow-hidden w-full data-[selected='true']:bg-primary-main",
            item.wrap && "whitespace-normal",
            !isMobile && "group-data-[parent=closed]:hidden",
          )}
        >
          {item.name}
          <Icon
            name="ArrowArrowNoLineDown"
            className={twMerge(
              "h-5 w-5 fill-current text-neutral-darkest ml-auto transition-transform duration-200 md:group-hover:text-neutral-white",
              isOpen && "rotate-180",
            )}
          />
        </span>
      </Collapsible.Trigger>
      <Collapsible.CollapsibleContent
        className={twMerge(
          "overflow-hidden mt-3",
          isOpen && "animate-collapsible-down",
          !isOpen && "animate-collapsible-up",
        )}
      >
        <ul className="flex flex-col gap-2">
          {item.children.map((navItem) => {
            if (navItem.allowedRoles && !hasPermission(navItem.allowedRoles)) {
              return null;
            }

            return (
              <NavLink
                to={navItem.path}
                key={navItem.name}
                onClick={onSelectNavItem}
                end
              >
                {({ isActive }) => (
                  <li
                    className="text-neutral-darker text-base py-3 px-6 hover:bg-primary-dark hover:text-neutral-white w-full h-full rounded-sm data-[selected='true']:bg-primary-lighter data-[selected='true']:text-primary-main data-[selected='true']:font-bold"
                    data-selected={isActive ? "true" : "false"}
                  >
                    <span className="font-base">{navItem.name}</span>
                  </li>
                )}
              </NavLink>
            );
          })}
        </ul>
      </Collapsible.CollapsibleContent>
    </Collapsible.Root>
  );
};

interface NavigationProps {
  isOpen: boolean;
  handleCloseSidebar: () => void;
}

export const Navigation = ({ handleCloseSidebar, isOpen }: NavigationProps) => {
  const isMobile = Hooks.useMediaQuery("mobile");
  const isVerticalAvec = Hooks.useCheckVertical("avec");

  const navItems: Array<NavItem> = [
    {
      name: "Home",
      wrap: false,
      icon: SidebarIcons.home,
      path: routes.dashboard || "",
    },
    {
      name: "Extrato",
      wrap: false,
      icon: SidebarIcons.receipt,
      path: routes.statements || "",
    },
    {
      name: "Pagar",
      wrap: false,
      icon: SidebarIcons["cash-out"],
      path: "/cash-out",
      allowedRoles: [
        "Proprietário",
        "Administrador",
        "Moderador",
        "Financeiro",
      ],
      children: [
        {
          name: "Boleto",
          wrap: false,
          path:
            (isMobile ? routes.cashOutBoleto : routes.cashOutBoletoCopiaCola) ||
            "",
        },
        {
          name: "Pix",
          wrap: false,
          path:
            (isMobile ? routes.cashOutPix : routes.cashOutPixCopiaCola) || "",
        },
      ],
    },
    {
      name: "Pix",
      wrap: false,
      icon: SidebarIcons.pix,
      path: routes.pixDashboard || "",
      allowedRoles: ["Proprietário", "Administrador", "Moderador"],
    },
    {
      name: "Transferir",
      wrap: false,
      icon: SidebarIcons.transfers,
      path: routes.transfers || "",
      allowedRoles: [
        "Proprietário",
        "Administrador",
        "Moderador",
        "Financeiro",
      ],
    },
    {
      name: "Cobrança",
      wrap: false,
      icon: SidebarIcons.charge,
      path: routes.charge || "",
      conditionToHide: isVerticalAvec,
      allowedRoles: [
        "Proprietário",
        "Administrador",
        "Moderador",
        "Financeiro",
      ],
    },
    {
      name: "Lançamentos",
      wrap: false,
      icon: SidebarIcons["fact-check"],
      path: routes.pendingTransactions || "",
      allowedRoles: ["Proprietário", "Administrador", "Moderador"],
    },
    {
      name: "Pay",
      wrap: false,
      icon: SidebarIcons.pay,
      path: routes.dashboardPay || "",
      conditionToHide: isVerticalAvec,
      children: [
        {
          name: "Vendas",
          wrap: false,
          path: routes.salesReport || "",
        },
        {
          name: "Recebimentos",
          wrap: false,
          path: routes.dashboardPayAnticipationReceipt || "",
        },
        {
          name: "Agenda futura",
          wrap: false,
          path: routes.futureReceiptsCalendar || "",
        },
      ],
    },
    {
      name: "Configurações",
      wrap: false,
      icon: SidebarIcons.settings,
      path: routes.settings || "",
      allowedRoles: ["Proprietário", "Administrador", "Moderador"],
    },
  ];

  return (
    <nav>
      <ul className="flex flex-col gap-2">
        {navItems.map((item) => {
          if (item.children && !item.conditionToHide)
            return (
              <li key={item.name}>
                <CollapsibleItem
                  item={item}
                  sidebarState={isOpen}
                  handleCloseSidebar={handleCloseSidebar}
                />
              </li>
            );

          if (
            (item.allowedRoles && !hasPermission(item.allowedRoles)) ||
            item.conditionToHide
          ) {
            return null;
          }

          return (
            <NavLink
              to={item.path}
              key={item.name}
              onClick={handleCloseSidebar}
            >
              {({ isActive }) => (
                <li
                  data-parent={isOpen ? "open" : "closed"}
                  className="flex h-12 items-center rounded-sm p-3 hover:bg-primary-dark hover:text-neutral-white data-[selected='true']:bg-primary-main data-[selected='true']:fill-neutral-white data-[selected='true']:text-neutral-white w-full gap-2 group"
                  data-selected={isActive}
                >
                  <item.icon className="h-6 w-6 min-w-8" />
                  <span
                    data-selected={isActive}
                    className={twMerge(
                      "visible flex text-left font-base text-xs/6 font-bold hover:text-neutral-white text-neutral-darkest opacity-100 data-[selected=true]:text-neutral-white gap-2 justify-between items-center overflow-hidden w-full group-data-[parent=closed]:hidden md:group-hover:text-neutral-white",
                      item.wrap && "whitespace-normal",
                    )}
                  >
                    {item.name}
                  </span>
                </li>
              )}
            </NavLink>
          );
        })}
      </ul>
    </nav>
  );
};
