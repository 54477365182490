import React from "react";
import type { ComponentProps } from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { twMerge } from "tailwind-merge";

const Root = DropdownMenuPrimitive.Root;
const Separator = DropdownMenuPrimitive.Separator;

function DropDownMenuArrow({
  className,
  ...props
}: ComponentProps<typeof DropdownMenuPrimitive.Arrow>) {
  return (
    <DropdownMenuPrimitive.Arrow
      className={twMerge(
        "fill-neutral-white stroke-neutral-lighter",
        className,
      )}
      {...props}
    />
  );
}

const Content = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 5, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={twMerge(
        "data-[side=bottom]:animate-slide-up-and-fade data-[side=top]:animate-slide-down-and-fade z-50 inline-flex h-full max-h-[180px] w-56 flex-col items-start self-stretch overflow-y-auto rounded border border-primary-main bg-neutral-white",
        className,
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
Content.displayName = DropdownMenuPrimitive.Content.displayName;

const Item = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={twMerge(
      "relative flex h-10 w-full cursor-pointer items-center gap-inset-xs border-none bg-neutral-white p-4 hover:bg-primary-lighter hover:outline-none",
      className,
    )}
    {...props}
  />
));
Item.displayName = DropdownMenuPrimitive.Item.displayName;

export const DropdownMenu = Object.assign(Root, {
  Separator,
  Arrow: DropDownMenuArrow,
  Trigger: DropdownMenuTrigger,
  Content,
  Item,
});
