import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { HeaderProps } from "@/components";
import {
  isAuthenticated,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";

type EventContext = {
  eventDetail: HeaderProps | null;
  setEventDetail: (props: HeaderProps | null) => void;
};

const EventDetailContext = createContext<EventContext>({
  eventDetail: null,
  setEventDetail: () => null,
});

export const EventDetailProvider = ({ children }: { children: ReactNode }) => {
  const [eventDetail, setEventDetail] = useState<HeaderProps | null>(null);

  const handleEventDetail = useCallback((props: HeaderProps | null) => {
    setEventDetail(props);
  }, []);

  const value = useMemo(
    () => ({
      eventDetail,
      setEventDetail: handleEventDetail,
    }),
    [eventDetail, handleEventDetail],
  );

  useEffect(() => {
    const eventCallback = async (event: Event) => {
      const customEvent = event as CustomEvent<HeaderProps>;
      const { detail } = customEvent || {};
      handleEventDetail({ ...detail, show: await isAuthenticated() });
    };
    window.addEventListener("headerChange", eventCallback);

    return () => window.removeEventListener("headerChange", eventCallback);
  }, [handleEventDetail]);

  return (
    <EventDetailContext.Provider value={value}>
      {children}
    </EventDetailContext.Provider>
  );
};

export const useEventDetail = () => useContext(EventDetailContext);
