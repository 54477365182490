import { ValidationError } from "@/types";

export function isLengthBetween8And32(password: string): boolean {
  const minLength = 8;
  const maxLength = 32;
  return password.length >= minLength && password.length <= maxLength;
}

export function containsLettersAndNumbers(password: string): boolean {
  const hasLetter = /[a-zA-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  return hasLetter && hasNumber;
}

export function containsUpperCase(password: string): boolean {
  return /[A-Z]/.test(password);
}

function containsNumbers(password: string): boolean {
  return /\d/.test(password);
}

function containsLowerCase(password: string): boolean {
  return /[a-z]/.test(password);
}

function containsSpecialChar(password: string): boolean {
  return /[^a-zA-Z0-9]/.test(password);
}

export function comparePasswords(
  password: string,
  confirmPassword: string,
): boolean {
  return password === confirmPassword;
}

type ValidatePasswordParams = {
  password: string;
  confirmPassword: string;
  fields: ValidationError[];
};

export function validatePassword({
  password,
  fields,
  confirmPassword,
}: ValidatePasswordParams): ValidationError[] {
  if (password === "") {
    return fields.map((field) => ({ ...field, status: "idle" }));
  }

  const tests = {
    isLengthBetween8And32: isLengthBetween8And32(password),
    containsNumbers: containsNumbers(password),
    containsLettersAndNumbers: containsLettersAndNumbers(password),
    containsUpperCase: containsUpperCase(password),
    containsLowerCase: containsLowerCase(password),
    containsSpecialChar: containsSpecialChar(password),
    comparePasswords: comparePasswords(password, confirmPassword),
  } as Record<string, boolean>;

  fields.forEach((field) => {
    const testResult = tests[field.field];

    if (testResult) {
      field.status = "success";
    } else {
      field.status = "error";
    }
  });

  return fields;
}
