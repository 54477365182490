import React from "react";
import { AccountAvatar } from "@/components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Hooks, UserAccount } from "@hyperlocal/banking-utility";
import { Icons } from "./icons";

type CurrentAccountProps = {
  currentAccount: UserAccount | null;
  onOpenAccountSelection: () => void;
  isLoading: boolean;
};

export function CurrentAccount({
  currentAccount,
  onOpenAccountSelection,
  isLoading = false,
}: CurrentAccountProps) {
  const isDesktop = Hooks.useMediaQuery("desktop");

  return (
    <div
      className={
        "flex h-full flex-row items-center justify-end gap-2 mobile:p-4 desktop:pb-0 desktop:pl-8 desktop:pr-16 desktop:pt-0"
      }
    >
      <button
        type="button"
        disabled={isLoading}
        onClick={onOpenAccountSelection}
        className={"flex flex-row items-center justify-end gap-2"}
      >
        <AccountAvatar
          currentAccount={currentAccount}
          isLoading={isLoading}
          size={isDesktop ? "md" : "sm"}
        />
        <div
          className={
            "text-xs leading-6 text-neutral-darkest antialiased mobile:hidden"
          }
        >
          {isLoading ? (
            <div className="flex animate-pulse items-center justify-center gap-2">
              <div className="h-2 w-16 rounded bg-primary-light"></div>
              <span> | </span>
              <div className="h-2 w-16 rounded bg-primary-light"></div>
            </div>
          ) : (
            <div className="max-w-[310px] overflow-hidden text-ellipsis text-nowrap">
              <span className={"w-60 truncate"}>
                {currentAccount?.accountNumber} - {currentAccount?.accountDigit}
              </span>
              <span> | </span>
              <span className={"w-60 truncate"}>
                {" "}
                {currentAccount?.accountName}
              </span>
            </div>
          )}
        </div>
        <Icons.arrowDown
          className={"h-6 w-6 fill-neutral-darkest mobile:hidden"}
        />
      </button>
    </div>
  );
}

function Skeleton() {
  return (
    <div className="flex h-full flex-row items-center justify-end gap-2 mobile:p-4 desktop:pb-0 desktop:pl-8 desktop:pr-16 desktop:pt-0">
      <button
        type="button"
        disabled
        className={"flex flex-row items-center justify-end gap-2"}
      >
        <AccountAvatar currentAccount={null} isLoading={true} />
        <div
          className={
            "text-xs leading-6 text-neutral-darkest antialiased mobile:hidden"
          }
        >
          <div className="flex animate-pulse items-center justify-center gap-2">
            <div className="h-2 w-16 rounded bg-primary-light"></div>
            <span> | </span>
            <div className="h-2 w-16 rounded bg-primary-light"></div>
          </div>
        </div>
      </button>
    </div>
  );
}

CurrentAccount.Skeleton = Skeleton;
