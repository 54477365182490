import { useEffect } from "react";
import { toggleHeader } from "@/utils/location";

export function EmptyHeader() {
  useEffect(() => {
    toggleHeader({ show: true });
  }, []);

  return null;
}
