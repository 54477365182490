import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { ScrollArea } from "../scroll-area";
import { SidebarIcons } from "./sidebar-icons";
import { Navigation } from "./utils";

export const SidebarDesktop = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleMouseEnter = () => setIsOpen(true);
  const handleCloseSidebar = () => setIsOpen(false);

  return (
    <aside
      data-state={isOpen ? "open" : "closed"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleCloseSidebar}
      className="absolute bg-neutral-white z-50 top-0 min-h-[100vh] h-full shadow-full py-4 w-[72px] data-[state=open]:w-[256px] transition-width duration-300 group/root overflow-hidden whitespace-nowrap flex flex-col"
    >
      <ScrollArea className={twMerge("overflow-hidden px-2", isOpen && "px-3")}>
        <SidebarIcons.hyperlocal
          className={twMerge(
            "pl-[10px] w-full flex-shrink-0 box-content mb-7",
            !isOpen && "w-[42px]",
          )}
        />
        <Navigation handleCloseSidebar={handleCloseSidebar} isOpen={isOpen} />
      </ScrollArea>
    </aside>
  );
};
