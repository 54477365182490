import { Icons, Navigation, SelectAccount } from "@/components";
import { OnSelectAccount } from "@/types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { UserAccount } from "@hyperlocal/banking-utility";
import { Sheet } from "@hyperlocal/vital2";

type HeaderMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  currentAccount: UserAccount | null;
  accounts: UserAccount[];
  onSelectAccount: OnSelectAccount;
  onOpenUpdatePassword: () => void;
  isLoading: boolean;
};

export function HeaderSheet({
  isOpen,
  onClose,
  currentAccount,
  accounts,
  onSelectAccount,
  onOpenUpdatePassword,
  isLoading = false,
}: HeaderMenuProps) {
  return (
    <Sheet.Root open={isOpen} onOpenChange={onClose}>
      <Sheet.Content className="flex h-full w-full max-w-full flex-col !border-l-0 bg-neutral-white font-base lg:!max-w-[294px] lg:!px-4 mobile:!p-0">
        <Sheet.Header className={"mt-6 lg:mt-8 mobile:p-inset-lg"}>
          <SelectAccount
            isLoading={isLoading}
            currentAccount={currentAccount}
            accounts={accounts}
            onSelectAccount={onSelectAccount}
          />
        </Sheet.Header>

        <Navigation
          onOpenUpdatePassword={onOpenUpdatePassword}
          isLoading={isLoading}
          onClose={onClose}
        />

        <Sheet.Footer className="flex h-full w-full flex-col items-end justify-end sm:!flex-col lg:!hidden">
          <div className="flex h-16 w-full items-center justify-center gap-2 bg-primary-main">
            <Icons.logo />
            <Icons.logoText />
          </div>
        </Sheet.Footer>
      </Sheet.Content>
    </Sheet.Root>
  );
}
