import { validatePassword } from "@/utils";
import {
  FormField,
  OnUpdatePasswordSuccessPayload,
  State,
  ToggleConfirmUpdatePasswordOpenPayload,
  ToggleSheetOpenPayload,
  ToggleShowFieldPayload,
  ToggleUpdatePasswordOpenPayload,
  UpdateFieldPayload,
} from "./update-password.types";

export function updateField({
  fieldValue,
  fieldName,
  currentState,
}: UpdateFieldPayload): State {
  switch (fieldName) {
    case "newPassword":
      return {
        ...currentState,
        newPassword: fieldValue,
        validationErrors: validatePassword({
          password: fieldValue,
          confirmPassword: currentState.confirmPassword,
          fields: currentState.validationErrors,
        }),
      };
    case "confirmPassword":
      return {
        ...currentState,
        confirmPassword: fieldValue,
        validationErrors: validatePassword({
          password: currentState.newPassword,
          confirmPassword: fieldValue,
          fields: currentState.validationErrors,
        }),
      };
    case "oldPassword":
      return {
        ...currentState,
        oldPassword: fieldValue,
      };
    default:
      return currentState;
  }
}
const NEW_PASSWORD_FIELD = "newPassword";
export function toggleFieldVisibility({
  field,
  state,
}: ToggleShowFieldPayload): State {
  const fieldMap: { [key in FormField]: keyof State } = {
    oldPassword: "showPassword",
    confirmPassword: "showConfirmPassword",
    newPassword: NEW_PASSWORD_FIELD,
  };

  return {
    ...state,
    [fieldMap[field]]: !state[fieldMap[field]],
  };
}

export function toggleSheetOpen({ state }: ToggleSheetOpenPayload): State {
  return {
    ...state,
    isSheetOpen: !state.isSheetOpen,
  };
}

export function toggleUpdatePasswordOpen({
  state,
}: ToggleUpdatePasswordOpenPayload): State {
  return {
    ...state,
    isUpdatePasswordOpen: !state.isUpdatePasswordOpen,
  };
}

export function toggleConfirmUpdatePasswordOpen({
  state,
}: ToggleConfirmUpdatePasswordOpenPayload): State {
  return {
    ...state,
    isConfirmUpdatePasswordOpen: !state.isConfirmUpdatePasswordOpen,
  };
}

export function onUpdatePasswordSuccess({
  state,
}: OnUpdatePasswordSuccessPayload): State {
  return {
    ...state,
    isSheetOpen: false,
    isUpdatePasswordOpen: false,
    isConfirmUpdatePasswordOpen: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    validationErrors: [],
  };
}
