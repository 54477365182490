export function getAvatarFallback(name: string): string {
  if (!name) return "";

  const nameParts = name.split(" ");

  if (nameParts.length < 2) return "";

  const firstInitial = nameParts[0].charAt(0);
  const lastInitial = nameParts[nameParts.length - 1].charAt(0);

  return firstInitial + lastInitial;
}
