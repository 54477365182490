import { Suspense } from "react";
import { Provider } from "@hyperlocal/vital";
import { ThemeProvider } from "styled-components";
import { Header } from "./components";
import "./index.css";
import { EmptyHeader } from "@/components/empty-header/empty-header";
import { EventDetailProvider } from "@/stores";
import {
  Hooks,
  getDashboardRoutesData,
  getVertical,
  globalQueryClient,
  isAuthenticated,
  themeProvider,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: getDashboardRoutesData().sessionLogin,
    element: <EmptyHeader />,
  },
  {
    path: getDashboardRoutesData().sessionResetPassword,
    element: <EmptyHeader />,
  },
  {
    path: "/politicas-de-privacidade",
    element: <EmptyHeader />,
  },
  {
    path: "*",
    loader: async () => {
      return { isAuthenticated: await isAuthenticated() };
    },
    element: <Header />,
  },
]);

function App() {
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Suspense
      fallback={
        <div
          className={
            "flex h-10 animate-pulse flex-row items-center justify-end gap-2 mobile:p-4 desktop:pb-0 desktop:pl-8 desktop:pr-16 desktop:pt-0"
          }
        >
          <div className="flex items-center justify-center space-x-4">
            <div className={`h-6 w-6 rounded-full bg-primary-light`}></div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <div className="h-2 w-16 rounded bg-primary-light"></div>
            <span> | </span>
            <div className="h-2 w-16 rounded bg-primary-light"></div>
          </div>
        </div>
      }
    >
      <Provider theme={theme}>
        <ThemeProvider theme={themeProvider}>
          <QueryClientProvider client={globalQueryClient}>
            <EventDetailProvider>
              <RouterProvider router={router} />
              {process.env.NODE_ENV === "development" && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </EventDetailProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
      <Toaster position={isMobile ? "bottom-center" : "top-right"} />
    </Suspense>
  );
}

export default App;
