import { getAvatarFallback } from "@/components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { UserAccount } from "@hyperlocal/banking-utility";
import { Avatar } from "@hyperlocal/vital2";

type AccountAvatarProps = {
  currentAccount: UserAccount | null;
  size?: "sm" | "md" | "lg";
  isLoading: boolean;
};

export function AccountAvatar({
  currentAccount,
  size = "sm",
  isLoading = false,
}: AccountAvatarProps) {
  if (isLoading) {
    return (
      <div className="animate-pulse flex justify-center items-center space-x-4">
        <div
          className={`rounded-full bg-primary-light ${
            size === "sm" && "h-6 w-6"
          } ${size === "lg" && "h-16 w-16"} `}
        ></div>
      </div>
    );
  }

  return (
    <Avatar.Root size={size} variant={"primary"}>
      <Avatar.Image src={""} alt={"avatar image"} />{" "}
      <Avatar.Fallback>
        {getAvatarFallback(currentAccount?.accountName ?? "")}
      </Avatar.Fallback>
    </Avatar.Root>
  );
}
