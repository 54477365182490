import React from "react";
import { AccountAvatar, DropdownMenu } from "@/components";
import { OnSelectAccount } from "@/types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { UserAccount } from "@hyperlocal/banking-utility";
import { Icons } from "./icons";

type SelectAccountProps = {
  currentAccount: UserAccount | null;
  accounts: UserAccount[];
  onSelectAccount: OnSelectAccount;
  isLoading: boolean;
};

export function SelectAccount({
  onSelectAccount,
  accounts,
  currentAccount,
  isLoading = false,
}: SelectAccountProps) {
  const onTouch = (e: React.TouchEvent<HTMLDivElement>) => e.stopPropagation();

  const onWheel = (e: React.WheelEvent<HTMLDivElement>) => e.stopPropagation();

  return (
    <div
      className={
        "relative inline-flex flex-col items-center justify-center gap-4"
      }
    >
      <AccountAvatar
        currentAccount={currentAccount}
        isLoading={isLoading}
        size={"lg"}
      />
      <DropdownMenu>
        <DropdownMenu.Trigger
          className={"group/trigger"}
          disabled={isLoading || accounts.length === 1}
        >
          {isLoading ? (
            <div
              className={
                "flex w-full animate-pulse flex-col items-center justify-center gap-2"
              }
            >
              <span className="h-4 w-32 rounded-full bg-gray-300"></span>
              <span className="h-4 w-16 rounded-full bg-gray-300" />
            </div>
          ) : (
            <>
              <div className={"flex flex-row items-center justify-center"}>
                <h6
                  className={
                    "line-clamp-2 overflow-hidden text-ellipsis text-pretty text-sm font-bold leading-md text-neutral-darkest antialiased lg:text-xs"
                  }
                >
                  {currentAccount?.accountName}
                </h6>
                {accounts.length > 1 && (
                  <Icons.arrowDown
                    className={
                      "h-6 w-6 fill-neutral-darkest transition group-data-[state=open]/trigger:rotate-180"
                    }
                  />
                )}
              </div>
              <span
                className={
                  "truncate text-x3s leading-md text-neutral-dark lg:text-x2s"
                }
              >
                Ag: {currentAccount?.branch} Conta:{" "}
                {currentAccount?.accountNumber}-{currentAccount?.accountDigit}
              </span>
            </>
          )}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          data-side="top"
          onWheel={onWheel} // Devida a alguma interferência do dropdown sendo chamado dentro do sheet
          onTouchStart={onTouch}
          onTouchEnd={onTouch}
          onTouchMove={onTouch}
        >
          {accounts?.map((item: UserAccount) => (
            <DropdownMenu.Item
              onClick={() => onSelectAccount(item)}
              key={item.accountId}
              className={"group"}
            >
              <span
                data-selected={currentAccount?.accountName === item.accountName}
                className={
                  "w-60 truncate text-xs data-[selected=true]:text-primary-main"
                }
              >
                {item?.accountNumber}-{item?.accountDigit} | {item?.accountName}
              </span>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  );
}
