import { ValidationError } from "@/types";
import { icons } from "./icons";

export function PasswordValidation({ errors }: { errors: ValidationError[] }) {
  return (
    <div className="flex flex-col gap-2">
      {errors.map((error, index) => {
        return (
          <div key={index} className="flex items-center gap-2">
            {error.status === "error" && <icons.error />}
            {error.status === "success" && <icons.success />}
            {error.status === "idle" && <icons.empty />}
            <span
              data-status={error.status}
              className="data-[status=error]:text-status-error-dark data-[status=idle]:text-neutral-main data-[status=success]:text-status-success-dark"
            >
              {error.label}
            </span>
          </div>
        );
      })}
    </div>
  );
}
