import React from "react";
import { PasswordValidation } from "@/components";
import type { FormField } from "@/stores";
import { usePasswordStore } from "@/stores";
import Icon from "@hyperlocal/vital-icons";
import { Button, Sheet, TextField } from "@hyperlocal/vital2";

type ChangePasswordProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmChangePassword: () => void;
};

export function ChangePassword({
  isOpen,
  onClose,
  onConfirmChangePassword,
}: ChangePasswordProps) {
  const currentState = usePasswordStore((state) => state);
  const updateField = usePasswordStore((state) => state.updateField);
  const toggleFieldVisibility = usePasswordStore(
    (state) => state.toggleFieldVisibility,
  );
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name as FormField;
    const fieldValue = event.target.value;
    updateField({ fieldName, fieldValue });
  };

  const handleTogglePasswordVisibility = (field: FormField) => {
    toggleFieldVisibility({ field });
  };

  const passwordError = currentState.validationErrors.find(
    (error) => error.field === "comparePasswords" && error.status === "error",
  );

  const isDisabledButton = () => {
    const { oldPassword, newPassword, confirmPassword } = currentState;

    if (!oldPassword || !newPassword || !confirmPassword) return true;

    const allValidationsPassed = currentState.validationErrors.every(
      (error) => error.status === "success",
    );

    return !allValidationsPassed;
  };

  return (
    <Sheet.Root open={isOpen} onOpenChange={onClose}>
      <Sheet.Content className={"[&_>_button]:hidden"}>
        <Sheet.Header>
          <div className="flex w-full justify-between">
            <Sheet.Title className="font-base text-md/9 font-bold">
              Alterar senha
            </Sheet.Title>
            <Sheet.Trigger asChild>
              <button>
                <Icon name="GeralClose" className="w-6 h-6 fill-black" />
              </button>
            </Sheet.Trigger>
          </div>
        </Sheet.Header>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onConfirmChangePassword();
          }}
          className={"flex h-full w-full flex-col gap-6"}
        >
          <div>
            <TextField.Label htmlFor="oldPassword">Senha atual</TextField.Label>

            <TextField.Root>
              <TextField.Input
                id="oldPassword"
                name="oldPassword"
                type={currentState.showPassword ? "text" : "password"}
                placeholder="Digite aqui"
                onChange={handleInputChange}
              />
              <button
                type="button"
                aria-label="show-password"
                onClick={() => handleTogglePasswordVisibility("oldPassword")}
              >
                <TextField.Slot
                  name={
                    currentState.showPassword
                      ? "ComputersEye"
                      : "ComputersEyeSlash"
                  }
                />
              </button>
            </TextField.Root>
          </div>
          <div>
            <TextField.Label
              htmlFor="new-password"
              className={passwordError ? "text-status-error-dark" : undefined}
            >
              Nova senha
            </TextField.Label>
            <TextField.Root>
              <TextField.Input
                id="new-password"
                name="newPassword"
                status={passwordError ? "error" : undefined}
                type={currentState.showNewPassword ? "text" : "password"}
                placeholder="Digite aqui"
                onChange={handleInputChange}
              />
              <button
                type="button"
                aria-label="show-new-password"
                onClick={() => handleTogglePasswordVisibility("newPassword")}
              >
                <TextField.Slot
                  name={
                    currentState.showNewPassword
                      ? "ComputersEye"
                      : "ComputersEyeSlash"
                  }
                />
              </button>
            </TextField.Root>
            {passwordError && (
              <TextField.Helper className="text-status-error-dark">
                As senhas não conferem
              </TextField.Helper>
            )}
          </div>
          <div>
            <TextField.Label
              htmlFor="confirm-password"
              className={passwordError ? "text-status-error-dark" : undefined}
            >
              Confirmar nova senha
            </TextField.Label>
            <TextField.Root>
              <TextField.Input
                id="confirm-password"
                name="confirmPassword"
                status={passwordError ? "error" : undefined}
                type={currentState.showConfirmPassword ? "text" : "password"}
                placeholder="Digite aqui"
                onChange={handleInputChange}
              />
              <button
                type="button"
                aria-label="show-confirm-new-password"
                onClick={() =>
                  handleTogglePasswordVisibility("confirmPassword")
                }
              >
                <TextField.Slot
                  name={
                    currentState.showConfirmPassword
                      ? "ComputersEye"
                      : "ComputersEyeSlash"
                  }
                />
              </button>
            </TextField.Root>
            {passwordError && (
              <TextField.Helper className="text-status-error-dark">
                As senhas não conferem
              </TextField.Helper>
            )}
          </div>

          <PasswordValidation
            errors={currentState.validationErrors.filter(
              (error) => error.field !== "comparePasswords",
            )}
          />
          <div className="flex h-full w-full flex-col justify-end">
            <Button.Root fullWidth type="submit" disabled={isDisabledButton()}>
              Trocar senha
            </Button.Root>
          </div>
        </form>
      </Sheet.Content>
    </Sheet.Root>
  );
}
