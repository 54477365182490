import { HeaderProps } from "@/components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { setHeader } from "@hyperlocal/banking-utility";

export function isPublicRoute() {
  const PUBLIC_ROUTES = [
    "/session/login",
    "/session/reset-password",
    "/politicas-de-privacidade",
  ];

  return PUBLIC_ROUTES.includes(window.location.pathname);
}

export function toggleHeader(headerProps: HeaderProps) {
  const hide = headerProps.show;
  const mainElement = document.getElementById("hyperlocal-main");

  if (mainElement) {
    mainElement.classList.toggle("public-route", hide);
    mainElement.classList.toggle("private-route", !hide);
    setHeader({ ...headerProps, show: !hide });
  }
}
