import { useMemo } from "react";
import {
  getDashboardRoutesData,
  signOutUseCase,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Link } from "react-router-dom";
import { Icons } from "./icons";

type NavigationProps = {
  onOpenUpdatePassword: () => void;
  isLoading: boolean;
  onClose: () => void;
};

export function Navigation({
  onOpenUpdatePassword,
  onClose,
  isLoading = false,
}: NavigationProps) {
  const navItems = useMemo(
    () => [
      {
        type: "link",
        href: `${getDashboardRoutesData().settings}?tab=dados-da-conta`,
        icon: <Icons.person className="h-6 w-6 fill-neutral-darkest" />,
        label: "Dados da conta",
      },
      {
        type: "link",
        href: getDashboardRoutesData().contracts,
        icon: <Icons.description className="h-6 w-6 fill-neutral-darkest" />,
        label: "Contratos",
      },
      {
        type: "button",
        onClick: onOpenUpdatePassword,
        icon: <Icons.changePassword className="h-6 w-6 fill-neutral-darkest" />,
        label: "Alterar senha",
      },
      {
        type: "link",
        icon: <Icons.faq className="h-6 w-6 fill-neutral-darkest" />,
        label: "FAQ",
        href: "https://www.youtube.com/playlist?list=PLBCVnRxwpZHIULpbBQjCADKrq89nC3jgf",
      },
      {
        type: "button",
        onClick: async () => {
          await signOutUseCase.execute();
          onClose();
        },
        icon: <Icons.logout className="h-6 w-6 fill-neutral-darkest" />,
        label: "Sair",
      },
    ],
    [onOpenUpdatePassword, onClose],
  );

  if (isLoading) {
    return (
      <nav className="flex animate-pulse flex-col gap-2">
        <ul>
          {navItems.map((item, index) => (
            <li key={index}>
              <span className="flex h-12 w-full items-center justify-between gap-2 rounded-sm bg-neutral-white text-xs font-bold leading-6 text-neutral-darkest mobile:p-inset-lg">
                <div className="flex w-full gap-2">
                  <span className="h-4 w-4 rounded-full bg-gray-300"></span>
                  <span className="h-4 w-32 rounded-full bg-gray-300"></span>
                </div>
                <span className="h-4 w-4 rounded-full bg-gray-300"></span>
              </span>
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  return (
    <nav className="flex flex-col gap-2">
      <ul>
        {navItems.map((item, index) => (
          <li key={index}>
            {item.type === "link" ? (
              <Link
                to={item.href as string}
                onClick={onClose}
                className="flex h-12 w-full items-center justify-between gap-2 rounded-sm bg-neutral-white text-xs font-bold leading-6 text-neutral-darkest mobile:p-inset-lg"
                target={item.label === "FAQ" ? "_blank" : undefined}
              >
                <div className="flex gap-2">
                  {item.icon}
                  <span>{item.label}</span>
                </div>
                <Icons.arrowRight className="h-6 w-6 fill-neutral-darkest lg:hidden" />
              </Link>
            ) : (
              <button
                type="button"
                onClick={item.onClick}
                className="flex h-12 w-full items-center justify-between gap-2 rounded-sm bg-neutral-white text-xs font-bold leading-6 text-neutral-darkest mobile:p-inset-lg"
              >
                <div className="flex gap-2">
                  {item.icon}
                  <span>{item.label}</span>
                </div>
                <Icons.arrowRight className="h-6 w-6 fill-neutral-darkest lg:hidden" />
              </button>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}
