import {
  accountsQueryKeyFactory,
  GetAccountInfoUseCaseRequest,
  getAccountUseCase,
  QueryConfig,
  useAccountStore,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";

export type UseAccountInfo = {
  config?: QueryConfig<typeof getAccountInfo>;
} & GetAccountInfoUseCaseRequest;

async function getAccountInfo({ accountId }: GetAccountInfoUseCaseRequest) {
  const { updateAccountId, updateAccount } = useAccountStore.getState();

  const accountResponse = await getAccountUseCase.execute({ accountId });

  if (accountResponse.isFailure()) {
    throw new Error("Falha ao buscar o detalhes da conta.");
  }

  updateAccountId(accountId);
  updateAccount(accountResponse.value);
  return accountResponse.value;
}

export const useAccountInfo = ({ accountId, config = {} }: UseAccountInfo) => {
  const query = useQuery({
    queryKey: accountsQueryKeyFactory.detail(accountId),
    queryFn: () => getAccountInfo({ accountId }),
    ...config,
  });
  return {
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    refetch: query.refetch,
  };
};
