import React from "react";
import Icon, { IconProps } from "@hyperlocal/vital-icons";

type HeaderIconProps = {
  icon: IconProps["name"];
  testId: string;
  onClick?: () => void;
};

export function HeaderIcon({ icon, testId, onClick }: HeaderIconProps) {
  return icon ? (
    <div className={"flex justify-center items-center"}>
      <Icon
        className={"w-6 h-6 fill-neutral-darkest"}
        data-testid={testId}
        name={icon}
        onClick={onClick}
      />
    </div>
  ) : null;
}
