import React from "react";
import { HeaderProps } from "./types";

export function HeaderLink({
  Link,
  testId,
}: Pick<HeaderProps, "Link" | "testId">) {
  return Link ? (
    <div
      className={
        "font-base text-primary-main text-xs font-medium leading-md no-underline flex items-center flex-none order-1 flex-grow-0"
      }
    >
      <Link data-testid={testId?.Link} />
    </div>
  ) : null;
}
